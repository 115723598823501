<template>
  <div id="app" @click="appClick">
    <div v-if="!$route.meta.isAppMain" class="appMain">
      <CurrencySidebarVue />
      <div class="appRight">
        <CurrencyHeader />
        <CurrencyTagNavigation />
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive" class="routerView" :key="$route.fullPath" />
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive" class="routerView" :key="$route.fullPath" />
      </div>
    </div>
    <router-view v-else />
    <div style="
        opacity: 0;
        position: absolute;
        bottom: 0;
        left: 0;
        transform: translate(-100%, -100%);
      ">
      <el-image id="imagePreview" style="max-width: 100%; max-height: 100%" :src="this.$store.state.pigImgUrl"
        :preview-src-list="this.$store.state.pigImgUrlList">
      </el-image>
    </div>
  </div>
</template>
<script>
import CurrencyHeader from "@/components/currencyHeader.vue";
import CurrencySidebarVue from "@/components/currencySidebar.vue";
import CurrencyTagNavigation from "@/components/currencyTagNavigation.vue";
export default {
  components: { CurrencyHeader, CurrencySidebarVue, CurrencyTagNavigation },
  data() {
    return {
      clickTime: new Date().getTime(),
      signOutTime: 2 * 60 * 60 * 1000,
      setIntervalId: null,
    };
  },
  mounted() {
    /**
     * 1、判断没有登录信息，并且不是在登录页的话跳转到登录页
     * 2、判断有登录信息去获取菜单
     */

    if (!localStorage.getItem("loginInfoGsCrm")) {
      this.$router.replace({
        name: "Login",
      });
    } else if (localStorage.getItem("loginInfoGsCrm")) {
      let loginInfoGsCrm = JSON.parse(localStorage.getItem("loginInfoGsCrm"));
      if (loginInfoGsCrm.id)
        this.$http
          .get(`employee/baseGetById/${loginInfoGsCrm.id}`)
          .then((res) => {
            localStorage.setItem("loginInfoGsCrm", JSON.stringify(res.data));
            this.getMenu();
          });
      else
        this.getMenu();
    }
    if (location.port) {
      // this.$store.state.baseUrl = "https://testcrmapi.ardentbiomed.com.cn";
      // this.$store.state.uploadImgUrl =
      //   "https://testcrmapi.ardentbiomed.com.cn/minio/uploadMinIoFile";
      this.$store.state.baseUrl = "http://192.168.1.233:9901";
      this.$store.state.baseUrl = "https://api.genefirst.com.cn";
      this.$store.state.uploadImgUrl =
        "http://192.168.1.233:9901/minio/uploadMinIoFile";
      this.$store.state.uploadImgUrl =
        "https://api.genefirst.com.cn/minio/uploadMinIoFile";
    }
    else {
      this.$store.state.baseUrl = "https://api.genefirst.com.cn";
      this.$store.state.uploadImgUrl =
        "https://api.genefirst.com.cn/minio/uploadMinIoFile";
    }

    this.setIntervalId = setInterval(() => {
      this.timeoutLogout();
    }, 1000 * 60);

    this.$store.state.uploadHeaders = {
      "access-token": localStorage.getItem("token"),
    };
  },
  methods: {
    timeoutLogout() {
      if (localStorage.getItem("loginInfoGsCrm")) {
        let currentTime = new Date().getTime();
        let tempTiem = currentTime - this.clickTime;
        if (tempTiem > this.signOutTime) {
          clearInterval(this.setIntervalId);
          this.$logout();
        }
      }
    },
    appClick() {
      this.clickTime = new Date().getTime();
    },
  },
  destroyed() {
    // localStorage.clear();
  },
};
</script>
<style lang="less">
// 上传多张图片，可查询修改样式

.multipleImage {
  display: flex;

  .item:hover {
    .itemHoverBg {
      display: flex;
    }
  }

  .item {
    position: relative;
    margin-right: 30px;

    .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 10px;
      width: 300px;
      height: 200px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }

    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 100%;
      height: 100%;
      line-height: 200px;
      text-align: center;
    }

    .avatar {
      max-width: 100%;
      max-height: 100%;
      display: block;
    }

    .txt {
      font-size: 14px;
      text-align: center;
      line-height: 40px;
    }

    .itemHoverBg {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 200px;
      background-color: rgba(205, 209, 219, 0.5);

      .itemHover {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        transform: translateY(-50%);
        display: flex;

        .none {
          flex: 1;
        }

        .btn {
          width: 36px;
          height: 36px;
          border-radius: 100%;
          position: relative;
          cursor: pointer;

          .uploadBtn {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            z-index: 1;

            .el-upload {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            }
          }

          >img {
            width: 36px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }
}

// 客户管理-客户列表客户状态通用样式
.cooperationStatusBox {
  display: flex;
  align-items: center;

  .dot {
    width: 16px;
    height: 16px;
    border-radius: 100%;
    margin-right: 4px;
  }
}

// 客户星级通用样式
.starIconBox {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .starIcon {
    width: 15px;
    margin-right: 4px;
  }
}

.secondary {
  color: @secondColor !important;
}

.dangerBtn {
  color: @dangerColor !important;
}

.dangerBtn.is-disabled,
.secondary.is-disabled {
  color: #c0c4cc !important;
}

// 一行两个表单统一样式
/** 表单一行两个  start */
.elFormItemW50 {
  max-width: 1100px;

  .el-form {
    display: flex;
    flex-wrap: wrap;
  }

  .el-form-item:nth-child(2n) {
    margin-right: 0 !important;
  }

  .el-form-item {
    width: 49%;
    display: flex !important;
    margin-right: 2% !important;

    .el-form-item__content {
      flex: 1;
      margin-left: 0 !important;

      .el-date-editor.el-input {
        width: 100%;
      }
    }
  }
}

/** 表单一行两个  end */
.operationBtn {
  max-width: 1100px;
  text-align: center;
  margin-top: 20px;
}

// 初始化样式
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.el-card {
  box-shadow: none !important;
  border-radius: 0 !important;
  border: none;
}

/* 单页面顶部查询条件统一样式  start*/
.spaceBetween {
  justify-content: space-between;
}

.el-cascader {
  .el-input {
    width: 95% !important;
  }

  input {
    width: 100% !important;
  }
}

.el-cascader.width100 {
  .el-input {
    width: 100% !important;
  }
}

.el-card__header {
  padding: 10px 20px 20px !important;
}

.query,
.spaceBetween {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .el-select,
  .el-input,
  .el-picker,
  .el-date-editor,
  .el-radio-group {
    margin-right: 10px;
    width: 150px;
  }

  .el-button,
  .el-select,
  .el-input,
  .el-picker,
  .el-date-editor,
  .el-radio-group {
    margin-top: 10px;
  }

  .el-select {
    .el-input {
      margin-left: 0;
      margin-top: 0;
      width: 100%;
    }
  }
}

.el-range-separator {
  width: 10% !important;
}

/* 单页面顶部查询条件统一样式  end*/

/* 上传图片通用样式修改 start*/
.width50height50 {
  .avatar-uploader-icon {
    width: 50px;
    height: 50px;
    line-height: 50px;
  }

  img {
    width: 50px;
    height: 50px;
    border-radius: 5px;
  }
}

.width40height40 {
  width: 40px;
  height: 40px;

  .avatar-uploader-icon {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }

  img {
    width: 40px;
    height: 40px;
    border-radius: 5px;
  }
}

.width200height200 {
  .avatar-uploader-icon {
    font-size: 30px;
    width: 200px;
    height: 200px;
    line-height: 200px;
  }

  img {
    max-width: 100%;
    max-height: 200px;
  }
}

.avatar-uploader-icon {
  color: #8c939d;
  text-align: center;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
}

/* 上传图片通用样式修改 end*/

// 表单中的备注
.formPs {
  color: #aaa;
}

.formPs.special {
  color: @mainColor;
  display: flex;
  align-items: center;
  line-height: 22px;

  .none {
    flex: 1;
  }

  img {
    width: 16px;
    margin-left: 10px;
  }
}

//表格中的操作栏统一样式
.tableOperation {
  .operationLine {
    color: #f0efee;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.el-dropdown-menu {
  .el-dropdown-menu__item {
    .el-button {
      width: 100%;
      text-align: center;
    }
  }
}

#app {
  color: #333;

  .appMain {
    display: flex;

    .appRight {
      flex: 1;
    }

    .routerView {
      height: ~"calc(100vh - 60px - 50px)"; // 减去header  减去tagNavigation
      width: ~"calc(100vw - 200px)";
      overflow-y: auto;
      position: relative;
      padding: 10px;
      background-color: #eee;
      text-align: justify;

      >.el-card {
        background-color: #fff;
      }

      .routerBack {
        position: absolute;
        top: 30px;
        right: 30px;
        z-index: 1;
      }

      //表格中底部的分页统一样式
      .floatRight {
        float: right;
        margin: 10px 0px;
      }

      // 标题加表单的样式
      .info {
        border: 1px solid #eee;
        padding-top: 30px;
        position: relative;
        margin-top: 40px;

        .title {
          position: absolute;
          top: -10px;
          left: 30px;
          background-color: #fff;
          padding-left: 20px;
          padding-right: 20px;
          font-size: 14px;
          color: #ddd;
        }
      }

      // 操作日志页面共同样式
      .journalPage {
        .el-table {
          margin-top: 20px;

          .keyValue {
            .key {
              color: #999;
            }
          }
        }
      }
    }
  }
}
</style>
